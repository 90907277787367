/**
 * CSV出力関数
 * @param {Array} data
 * @param {string} fileName - ダウンロードするCSVファイル名（製品マスタ.csv）
 */
export const ExportCSV = (data, fileName = '製品マスタ.csv') => {
    if (!data || data.length === 0) {
      alert('出力するデータがありません。');
      return;
    }
  
    // CSVのヘッダーを定義
    const headers = [
      '品番',
      '商品名',
      '商品名カナ',
      'PCRS',
      'JANコード',
      'サンテナ入数',
    ];
  
    // データをCSV形式に変換
    const csvRows = [headers.join(',')];
    data.forEach(row => {
      const values = [
        row.itemNo || '',
        row.itemName || '',
        row.itemKana || '',
        row.pcrsDivision || '',
        row.janCode || '',
        row.quantityInSuntenor !== undefined && row.quantityInSuntenor !== null && row.quantityInSuntenor !== '' 
        ? row.quantityInSuntenor 
        : 0,
      ];
      csvRows.push(values.map(value => `"${value}"`).join(','));
    });
  
    // UTF-8 with BOM
    const BOM = '\uFEFF';
    const csvContent = BOM + csvRows.join('\n');
  
    // Blobを作成してダウンロード
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};  