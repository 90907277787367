import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'

import { useHistory } from "react-router-dom"
import TruckIcon from '../image/menu-truck.png'
import storeDetailIcon from '../image/menu-icon2.png'
import HomeIcon from '../image/menu-salaried.png'
import LaborIcon from '../image/labor-icon.png'
import * as LogisticUtility from './Common/Utilties/LogisticUtility'
const MenuNav = styled.nav`
  display: none;
  animation-name: menu;
  animation-duration: .5s;
  animation-timing-function: ease;
  width: 350px;
  background: #cfe2f3;
  box-shadow: 2px 2px 6px rgba(0,0,0,.4);
  position: absolute;
  left 15px;
  top: 60px;
  z-index: 1300;

  @keyframes menu {
    from {
      opacity: 0;
      transform: translateX(0);
    }
    
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`
const MenuContentWrap  = styled.div`
  position: relative;
  display: flex; /* 縦並び */
  flex-direction: column;
  text-align: center;
  width: 330px;
  margin-top: 10px;
  margin-bottom: 10px;
`

const SubMenuContentWrap = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* 幅3等分 */
  grid-template-rows: 60px 60px 60px; /* 60pxの3行 */
  grid-gap: 5px; /* 隙間5px */
`

const NavigatorItemContent  = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  text-align: center;
  width: 110px;
  font-size: 14px;
`

// サービス内の機能へ遷移する項目
const NavigatorItem = (props) => {
  const {
    functionItem,
    onClick,
  } = props
  return (
    <NavigatorItemContent>
        { functionItem.data.functionMenuId !=='2030101001' &&
          <Tooltip title={functionItem.data.functionMenuName}>
            <Link key={functionItem.data.functionMenuId} to={functionItem.linkTo} data-service-menu-id={functionItem.data.functionMenuId} onClick={onClick} >
              <img alt={functionItem.data.functionMenuName} src={functionItem.img} width="32px" height="32px" />
              <div>{functionItem.data.functionMenuName}</div>
            </Link>
          </Tooltip>
        }
        {/* 売上管理Close */}
        { functionItem.data.functionMenuId ==='2030101001' &&
          <div>
            <img alt={functionItem.data.functionMenuName} src={functionItem.img} width="32px" height="32px" />
            <div>{functionItem.data.functionMenuName}</div>
          </div>
        }
    </NavigatorItemContent>
  )
}

NavigatorItem.propTypes = {
  functionItem: PropTypes.object,
  onClick: PropTypes.func
}

// 他サービスへ遷移する項目
const OtherServiceItem = (props) => {
  const {
    functionItem,
    onClick,
  } = props
  return (
    <NavigatorItemContent style={{cursor:functionItem.cursor}}>
      <Tooltip title={functionItem.toolTipName}>
        <div  onClick={onClick}>
          <img alt={functionItem.functionName} src={functionItem.img} width="32px" height="32px" />
          <div>{functionItem.functionName}</div>
        </div>
      </Tooltip>
    </NavigatorItemContent>
  )
}

OtherServiceItem.propTypes = {
  functionItem: PropTypes.object,
  onClick: PropTypes.func
}

const TrackNavigator = (props) => {
  //const [serviceList, setServiceList] = useState([])
  const loginUserInfo = useSelector(state => state.loginUserInfo);
  const isTruckUser = LogisticUtility.checkAFSTruckUser(loginUserInfo.grantNameId);
  const history = useHistory();

  useEffect(() => {
  // ログインユーザー情報の変更検知時のみ実行する
  // eslint-disable-next-line
  }, []);

  const showMenu = () => {
      props.setShowFlg(props.showFlg === 'none' ? 'block' : 'none');
  }

  const showTruckDetailMenu = () => {
    props.setShowFlg(props.showFlg === 'none' ? 'block' : 'none');
    history.push('/afstruck/truckDetail');
  }
  
  const showStoreDetailMenu = () => {
    props.setShowFlg(props.showFlg === 'none' ? 'block' : 'none');
    history.push('/afstruck/storeDetail');
  }
    
  const showMainMenu = () => {
    props.setShowFlg(props.showFlg === 'none' ? 'block' : 'none');
    history.push('/afstruck/Main');
  }

  const showLaborDetailMenu = () => {
    props.setShowFlg(props.showFlg === 'none' ? 'block' : 'none');
    history.push('/afstruck/laborDetail');
  }
  return (
  <MenuNav className='menu' style={{ display: props.showFlg }} onMouseLeave={showMenu} >
      <MenuContentWrap>
        <SubMenuContentWrap>
          <>
            <OtherServiceItem 
              key={'ホーム'} 
              functionItem={{
                functionName:'ホーム', 
                img:HomeIcon, 
                cursor:'pointer',
                toolTipName:'ホーム',
              }} 
              onClick={()=>{showMainMenu()}}>
            </OtherServiceItem>
            <OtherServiceItem 
              key={'配送実績一覧'} 
              functionItem={{
                functionName:'配送実績一覧', 
                img:TruckIcon, 
                cursor:'pointer',
                toolTipName:'配送実績一覧'
              }} 
              onClick={showTruckDetailMenu}>
            </OtherServiceItem>
            <OtherServiceItem 
              key={'労務実績一覧'} 
              functionItem={{
                functionName:'労務実績一覧', 
                img:LaborIcon, 
                cursor:'pointer',
                toolTipName:'労務実績一覧'
              }} 
              onClick={showLaborDetailMenu}>
            </OtherServiceItem>
            {!isTruckUser &&
            <OtherServiceItem 
              key={'店舗作業時間チェックレポート'} 
              functionItem={{
                functionName:'店舗作業時間チェックレポート', 
                img:storeDetailIcon, 
                cursor:'pointer',
                toolTipName:'店舗作業時間チェックレポート'
              }} 
              onClick={showStoreDetailMenu}>
            </OtherServiceItem>
            }
          </>
        </SubMenuContentWrap>
      </MenuContentWrap>
  </MenuNav>
  )
}

TrackNavigator.propTypes = {
  showFlg: PropTypes.string,
  setShowFlg: PropTypes.func,
  topPageFlg: PropTypes.bool,
}

export default TrackNavigator
