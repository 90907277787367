/**
 * CSV出力関数
 * @param {Array} data
 * @param {string} fileName - ダウンロードするCSVファイル名（店舗マスタ.csv）
 */
export const ExportCSV = (data, fileName = '店舗マスタ.csv') => {
    if (!data || data.length === 0) {
      alert('出力するデータがありません。');
      return;
    }
  
    // CSVのヘッダーを定義
    const headers = [
      'ID',
      'グループコード',
      '会社コード',
      '店コード',
      '正式名称',
      '略名称',
      'ローマ字',
      'カナ名称',
      '出荷チケット用名称',
      '仕分用名称',
      '郵便番号1',
      '郵便番号2',
      '住所1',
      '住所2',
      '電話番号',
      'FAX番号',
      '店舗区分',
      '請求先コード',
      '事業本部コード',
      '営事コード',
      '計上会社コード',
      'センターコード',
      '伝区',
      '仕入明細発行グループ',
      '仕入明細発行順',
      '週間受注使用区分',
      '開店日',
      '閉店日',
      'カゴ車単価',
      '更新担当者コード',
      '更新プログラム',
      '更新区分',
      '更新日付',
      '入力日付',
      '値付送信区分',
      'ヤオハンセンターコード',
      'FAX送信',
      '納品情報作成区分',
      '出力グループ',
      '出力順',
      '出庫リードタイム',
      '出荷ワッペン用順序',
      '運送手段',
      'マルエツチェーンコード',
      'マルエツSLセンターコード',
      '納品センターコード',
      '納品センター名',
      '売上計上区分',
      '集約店コード1',
      '集約店コード2',
      '集約店コード3',
      '集約店コード4',
      '集約店コード5',
    ];
  
    // データをCSV形式に変換
    const csvRows = [headers.join(',')];
    data.forEach(row => {
      const values = [
        row.id,
        row.groupCode,
        row.companyCode,
        row.storeCode,
        row.storeName,
        row.abbreviation,
        row.romaji,
        row.kana,
        row.shippingTicketName,
        row.shiwakeName,
        row.postNumber1,
        row.postNumber2,
        row.adress1,
        row.adress2,
        row.phoneNumber,
        row.faxNumber,
        row.storeDivision,
        row.billingAddressCode,
        row.jigyoHonbuCode,
        row.eijiCode,
        row.keijoCompanyCode,
        row.centerCode,
        row.denku,
        row.purchaseMeisaiHakkouGroup,
        row.purchaseMeisaiHakkouOrder,
        row.weeklyReceiveUseDivision,
        row.openDate,
        row.closeDate,
        row.basketCarUnitPrice,
        row.updateStaffCode,
        row.updateProgram,
        row.updateDivision,
        row.updateDate,
        row.inputDate,
        row.pricingSendDivision,
        row.yaohanCenterCode,
        row.faxSend,
        row.nohinInfoCreateDivision,
        row.outputGroup,
        row.outputOrder,
        row.syukkoReadTime,
        row.shippingWappenOrder,
        row.transportWay,
        row.maruetsuChainCode,
        row.maruetsuSlCenterCode,
        row.nohinCenterCode,
        row.nohinCenterName,
        row.salesRecordingDivision,
        row.aggregateStoreCode1,
        row.aggregateStoreCode2,
        row.aggregateStoreCode3,
        row.aggregateStoreCode4,
        row.aggregateStoreCode5,
      ];
      csvRows.push(values.map(value => `"${value || ''}"`).join(','));
    });
  
    // UTF-8 with BOM
    const BOM = '\uFEFF';
    const csvContent = BOM + csvRows.join('\n');
  
    // Blobを作成してダウンロード
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};  